export const Colors = {
  black: '#000',
  border: '#DBE5F0',
  brandAccent: '#FE5783',
  brandDanger: '#A0003B',
  brandSuccess: '#2EBF6A',
  docsHeaders: '#303346',
  gray0: '#F6FAFC',
  gray10: '#F0F4F9',
  gray20: '#EAEFF5',
  gray30: '#E3EAF3',
  gray40: '#DBE5F0',
  gray50: '#AFBED0',
  gray60: '#97A5B5',
  gray70: '#667380',
  gray80: '#4E5A65',
  gray90: '#35414B',
  gray100: '#1D2830',
  gray200: '#2E3440',
  text: '#1D2830',
  text80: '#AFBED0',
  text90: '#424E58',
  textAccent: '#3F5C71',
  white40: '#FFF9',
  white: '#FFFFFF',
  accentGreen: '#26A65B',
  accentBlue: '#4D79FF',
  accentOrange: '#F0706D',
  accentTeal: '#00D1AF',
  accentPurple: '#A1A8FF',
  primary: '#3B2FC9',
  primary70: '#4C48EF',
  primary50: '#6673FF',
  primary40: '#808AFF',
  primary30: '#A1A8FF',
  primary0: '#C4C9FF',
  primaryFaded: '#A1A8FF33',
  secondary: '#4C48EF',
  paleGreen: '#D6EBD0',
  fadedGreen: '#D6EBD033',
  fadedOrange: '#E59E8133',
  bgGreen: '#D1ECCE',
  bgBlue: '#646BF6',
  tan: '#FBE5CF',
  cobalt: '#002E70'
}